@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,600);
@import url(https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200);

@import "themes/sidebar";
@import "bootstrap-grid.css";
@import "multiselect";

:root {
    --sidebar_width    : 250px;
    --colorPrimary     : #313858;
    --colorPrimaryDark : #313858;
    --colorAccent      : #53B872;
    --bgColor          : #FFFFFF;
    --bgTiles          : #EEEEEE;
}

body {
    font-family              : "Lato", sans-serif;
    font-size                : 100%;
    -ms-text-size-adjust     : 100%;
    -webkit-text-size-adjust : 100%;
    -webkit-font-smoothing   : antialiased;
    -moz-osx-font-smoothing  : grayscale;
    text-rendering           : optimizeLegibility;
    margin                   : 0;
    padding                  : 0;
}

a {
    text-decoration    : none;
    -webkit-transition : all 0.6s ease;
    -moz-transition    : all 0.6s ease;
    transition         : all 0.6s ease;
}

a:hover {
    -webkit-transition : all 0.6s ease;
    -moz-transition    : all 0.6s ease;
    transition         : all 0.6s ease;
}

.h-100 {
    height : 100%;
}

.w-100 {
    width : 100%;
}

.ui.table {
    margin : 0;
}

.input_file {
    width    : 0.1px;
    height   : 0.1px;
    opacity  : 0;
    overflow : hidden;
    position : absolute;
    z-index  : -1;
}

textarea, .ui.form textarea {
    resize : none;
}

button, input, optgroup, select, textarea {
    font-size : 13px;
}

.ui.input > input {
    padding : 6px 10px;
}

div.window_content {
    height        : 100%;
    overflow-y    : auto;
    border-radius : 5px;
    background    : #FFFFFF;
    overflow-x    : auto;
}

span.form_header {
    background    : var(--colorPrimaryDark);
    display       : block;
    text-align    : center;
    color         : white;
    font-weight   : bold;
    font-size     : 15px;
    margin-bottom : 5px;
    padding       : 5px 10px;
    border-radius : 5px;
}

div.form_container {
    margin-bottom : 10px;
    padding       : 10px;
    height        : calc(100% - 50px);
    overflow-y    : auto;
    background    : white;
    border-radius : 5px;

    &.has_header {
        height        : calc(100% - 80px);
        margin-bottom : 10px;
    }

    img.center_logo {
        width      : auto;
        max-width  : 100%;
        min-width  : 150px;
        height     : auto;
        min-height : 100px;
        margin     : auto auto;
        border     : solid 1px lightgrey;
    }

    span.sub_header {
        display       : block;
        font-weight   : bold;
        font-size     : 14px;
        margin-bottom : 5px;
        background    : var(--colorPrimaryDark);
        color         : white;
        padding       : 5px 10px;
        border-radius : 5px;
        margin-top    : 15px;
    }

    span.label {
        display       : block;
        font-weight   : bold;
        font-size     : 14px;
        margin-bottom : 5px;
        margin-top    : 15px;
        color         : var(--colorPrimaryDark);
    }

    span.label_text {
        display       : block;
        font-size     : 15px;
        margin-bottom : 5px;
        margin-top    : 5px;
        color         : #565454;
        border        : solid 1px #DEDEDE;
        border-radius : 8px;
        padding       : 8px 10px;
    }

    label.small_hint {
        display    : block;
        font-size  : 11px;
        color      : #BA2D37;
        margin-top : 4px;
    }

    div.user_role {
        background    : var(--bgTiles);
        margin-bottom : 3px;
        padding       : 3px 10px;
        border-radius : 5px;

        &:last-child {
            margin-bottom : 0;
        }
    }
}

div.button_container {
    background : var(--bgTiles);
    padding    : 5px;
}

/*table information*/
.ui.table {
    min-width : 100%;

    thead {
        position   : sticky !important;
        top        : 0;
        z-index    : 2;
        background : var(--colorPrimaryDark);
    }

    tbody tr:hover {
        cursor : pointer;
    }

    &:not(.structured) {
        thead, tbody tr {
            display      : table;
            width        : 100%;
            table-layout : fixed;
        }
    }
}

.ui.inverted.grey.table {
    background-color : #E2E2E2 !important;
    color            : #000000 !important;
}

.ui.inverted.striped.table tbody tr:nth-child(2n), .ui.inverted.striped.table > tr:nth-child(2n) {
    background-color : #D4D4D4
}

.ui.selectable.inverted.table tbody tr:hover {
    background : var(--colorPrimary) !important;
    color      : white !important;
}

.ui.secondary.button, .ui.secondary.buttons .button, .ui.negative.button, .ui.negative.buttons .button {
    background : #BA2D37;
    border     : solid 1px transparent;
}

.ui.secondary.button:hover, .ui.secondary.buttons .button:hover, .ui.negative.button:hover, .ui.negative.buttons .button:hover {
    background-color : white;
    color            : #BA2D37;
    border-color     : #BA2D37;
}

.ui.primary.button, .ui.primary.buttons .button, .ui.positive.button, .ui.positive.buttons .button {
    background : var(--colorPrimaryDark);
    border     : solid 1px transparent;
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover, .ui.positive.button:hover, .ui.positive.buttons .button:hover {
    background-color : white;
    color            : var(--colorPrimaryDark);
    border-color     : var(--colorPrimaryDark);
}

input.date_input {
    width          : 100%;
    height         : 29px;
    padding        : 0 10px;
    line-height    : 29px;
    vertical-align : middle;
    border-radius  : 5px;
    border         : solid 1px lightgray;

    &:focus {
        border  : solid 1px #C3C1C1;
        outline : none;
    }
}

.react-tel-input {
    width     : 100%;
    font-size : 12px;

    .form-control {
        width  : 100%;
        height : 28px;
    }

    .country-list {
        width : 250px;
    }
}

div.login_window {
    background : #B1A4A4;
    height     : 100%;
    width      : 100%;
    position   : absolute;
    top        : 0;
    left       : 0;
    z-index    : 10;
}

div.login_form {
    div.login_logo {
        text-align : center;
    }

    div.login_logo img {
        width  : 250px;
        height : auto;
    }
}

div.pdf_modal {
    height : calc(100vh - 150px);
}

section.side_content {
    position       : absolute;
    left           : 17em;
    height         : 100%;
    top            : 0;
    overflow       : hidden;
    width          : calc(100% - 17em);
    padding        : 5px;
    flex-direction : column;
}

div.content_bar {
    background     : var(--bgTiles);
    border-radius  : 5px;
    padding        : 5px;
    display        : flex;
    height         : 40px;
    margin-bottom  : 10px;
    flex-direction : row;

    div.search_bar {
        flex           : 1;
        height         : 100%;
        vertical-align : middle;
        line-height    : 30px;

        & > div {
            display        : inline-block;
            width          : 180px;
            margin-right   : 5px;
            vertical-align : middle;

            & > :last-child {
                margin-right : 0;
            }
        }
    }

    div.content_buttons {
        height : 100%;
    }
}

div.content_container {
    background    : var(--bgTiles);
    border-radius : 5px;
    padding       : 5px;
    display       : flex;
    height        : calc(100% - 60px);
}

div.modal_container {
    background : var(--bgTiles);
    height     : auto;
    padding    : 10px;

    &.full_height {
        height : calc(100vh - 100px);
    }

    div.table_test {
        height        : auto;
        min-height    : 100%;
        background    : var(--bgTiles);
        padding       : 5px;
        border-radius : 5px;
        position      : relative;

        .floating_button {
            height        : 30px;
            width         : 30px;
            border-radius : 50%;
            background    : var(--colorAccent);
            bottom        : 5px;
            right         : 5px;
            padding       : 7px;
            position      : absolute;
            z-index       : 5;
            cursor        : pointer;

            &:hover {
                background : var(--colorPrimaryDark);
            }

            i {
                color     : white;
                font-size : 14px;
            }
        }
    }

    div.table_order {
        height        : calc(100% - 185px);
        background    : var(--bgTiles);
        padding       : 5px;
        border-radius : 5px;
        overflow-y    : auto;
    }

    div.table_payment {
        height        : 250px;
        background    : var(--bgTiles);
        padding       : 5px;
        border-radius : 5px;
        overflow-y    : auto;
    }

    span.bill_amount {
        display     : block;
        color       : var(--colorPrimaryDark);
        font-weight : bold;
        font-size   : 25px;
        text-align  : center;
        margin-top  : 10px;
    }
}

/*select input*/
.ui.dropdown, .ui.dropdown.selection {
    height         : 30px;
    min-height     : 30px;
    line-height    : 30px;
    vertical-align : middle;
    padding        : 0 15px;
}

.ui.dropdown, .ui.selection.dropdown .text {
    font-size : 13px;
}

.ui.selection.dropdown div.text {
    text-overflow : ellipsis;
    overflow      : hidden;
    white-space   : nowrap;
    width         : calc(100% - 10px);
    height        : 100%;
}

.ui.selection.dropdown > .delete.icon, .ui.selection.dropdown > .dropdown.icon, .ui.selection.dropdown > .search.icon {
    height  : 30px;
    padding : 8px 5px;
    margin  : -10px -10px 0 0;
}

/*message*/
.ui.message {
    line-height : 20px;
    padding     : 5px 10px;
    font-size   : 95%;

    p {
        font-weight : 600;
    }
}

.ui.form .error.message.show, .ui.form .success.message.show, .ui.form .warning.message.show {
    display       : block;
    margin-bottom : 4px;
    padding       : 5px 10px;
    font-size     : 12px;
}


div.home_dash {
    height                : 100%;
    background            : url('../images/wall_paper.jpeg') rgba(0, 0, 0, 0.5);
    background-size       : cover;
    background-blend-mode : multiply;
    margin                : -5px;
}


img.table_avatar {
    height        : 50px;
    width         : 50px;
    border-radius : 50%;
    border        : solid 3px #D3CECE;
    cursor        : pointer;

    &:hover {
        border-color : #949090;
    }
}

div.client_info_display {
    div.title {
        font-size   : 15px;
        font-weight : 600;
        color       : black !important;

        &:first-child {
            margin-top : 20px;
        }
    }

    div.text {
        font-size     : 14px;
        font-weight   : 500;
        color         : #494747 !important;
        margin-bottom : 10px;

        &:last-child {
            margin-bottom : 0;
        }
    }
}

span.stock_level {
    display       : inline-block;
    font-size     : 12px;
    border-radius : 5px;
    padding       : 3px 7px;
    color         : white;

    &.out {
        background-color : #BA2D37;
    }

    &.low {
        background-color : #A04A14;
    }

    &.in {
        background-color : #0F7819;
    }

    span {
        font-weight : bold;
    }
}


div.total_sale_price {
    font-size   : 30px;
    text-align  : center;
    color       : black;
    font-weight : bold;
}
