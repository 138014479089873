div.multiselect {
    position : relative;
    display  : inline-block;
    width    : 100%;
    height   : 30px;

    div.trigger {
        width          : 100%;
        height         : 30px;
        border         : solid 1px lightgray;
        line-height    : 30px;
        vertical-align : middle;
        border-radius  : 5px;
        padding        : 0 10px;
        cursor         : pointer;
        position       : relative;

        & > div {
            display        : inline-block;
            vertical-align : top;
            height         : 100%;
            line-height    : 28px;
        }

        div.selection {
            width : 20px;

            div {
                background    : var(--colorPrimaryDark);
                height        : 17px;
                display       : inline-block;
                width         : auto;
                line-height   : 17px;
                color         : white;
                padding       : 0 3px;
                font-size     : 12px;
                font-weight   : 600;
                border-radius : 3px;
            }
        }

        div.placeholder {
            width       : calc(100% - 40px);
            font-size   : 14px;
            border      : none;
            line-height : 28px;

            input {
                height       : 20px;
                width        : 100%;
                outline      : none;
                border       : none;
                border-image : none;
                box-sizing   : unset;
            }
        }

        div.caret {
            text-align : center;
            width      : 20px;

            &::after {
                font-family    : 'Material Icons Outlined', serif;
                font-weight    : 600;
                text-align     : center;
                vertical-align : middle;
                content        : '\e5cf';
                font-size      : 120%;
            }
        }
    }

    div.select_content {
        display       : none;
        width         : 100%;
        position      : absolute;
        left          : 0;
        top           : 0;
        outline       : 0;
        min-width     : max-content;
        background    : #ffffff;
        text-shadow   : none;
        text-align    : left;
        border        : 1px solid rgba(34, 36, 38, .15);
        transition    : opacity .1s ease;
        z-index       : 11;
        will-change   : transform, opacity;
        height        : auto;
        max-height    : calc(80vh);
        overflow-y    : auto;

        box-shadow    : 0 0 3px 0 rgba(0, 0, 0, .08);
        border-radius : .28571429rem .28571429rem 0 0;

        &.show {
            display : block;

            &.up {
                top    : auto;
                bottom : 100%;
            }
        }

        div.ui.checkbox {
            color            : black;
            padding          : 10px 16px;
            text-decoration  : none;
            display          : block;
            background-color : #f1f0f0;

            &:nth-child(2n -1) {
                background-color : #ffffff;
            }
        }

    }
}
